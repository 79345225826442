import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    username: null,
    email: null,
    user: {},
    isLoggedIn: false,
    propertyId: null,
    mainPropertyId: null,
    legalEntityId: null,
    acountHolderId: null,
    isVendor: false,
    isFranchise: false,
    country: null,
    countryCode: null,
    settings: {},
    franchises: [],
    vendors: [],
  },
  reducers: {
    setUsername: (state, action) => {
      state.username = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    signIn: (state, action) => {
      state.user = { ...state.user, ...action.payload };
      state.isLoggedIn = true;
    },
    signOut: (state) => {
      state.user = {};
      state.isLoggedIn = false;
    },
    logout: (state) => {
      state.user = {};
      state.isLoggedIn = false;
    },
    setPropertyId: (state, action) => {
      state.propertyId = action.payload;
    },
    setMainPropertyId: (state, action) => {
      state.mainPropertyId = action.payload;
    },
    setLegalEntityId: (state, action) => {
      state.legalEntityId = action.payload;
    },
    setAccountHoldId: (state, action) => {
      state.acountHolderId = action.payload;
    },
    setVendor: (state, action) => {
      state.isVendor = action.payload;
    },
    setFranchise: (state, action) => {
      state.isFranchise = action.payload;
    },
    setCountry: (state, action) => {
      state.country = action.payload;
    },
    setCountryCode: (state, action) => {
      state.countryCode = action.payload;
    },
    setSettings: (state, action) => {
      state.settings = action.payload;
    },
    setFranchises: (state, action) => {
      state.franchises = action.payload;
    },
    setVendors: (state, action) => {
      state.vendors = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setUsername,
  setEmail,
  logout,
  signIn,
  signOut,
  setPropertyId,
  setSettings,
  setVendor,
  setFranchise,
  setLegalEntityId,
  setAccountHoldId,
  setMainPropertyId,
  setCountry,
  setCountryCode,
  setFranchises,
  setVendors,
} = userSlice.actions;

export default userSlice.reducer;
