import React, { Component } from "react";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../assets/css/animate.min.css";
import "../../assets/css/style.css";
import "../../assets/vendors/iconic-fonts/cryptocoins/cryptocoins-colors.css";
import "../../assets/vendors/iconic-fonts/cryptocoins/cryptocoins.css";
import "../../assets/vendors/iconic-fonts/flat-icons/flaticon.css";
import "../../assets/vendors/iconic-fonts/font-awesome/css/all.min.css";
import { setCurrentUser } from "../../redux/user/user.action";
import Routes from "../_Routes";
import { logout } from "../../redux/user/userSlice";
import { withRouter } from "react-router";
import { APIBaseURL } from "../../services/APIUrl";
import { ThemeProvider } from "@mui/material/styles";
import IdleTimer from "./IdleTimer";
import ReactGA from "react-ga";
import { QueryClient, QueryClientProvider } from "react-query";
import { LoadingProvider } from "contexts/LoadingContext";
import theme from "theme";
import { DialogProvider } from "contexts/DialogProvider";
import Dialog from "components/commonComps/Dialog";

ReactGA.initialize("G-R6JHW8GW6T");

var timer;

// Higher-order component to check server status
function withServerStatusCheck(WrappedComponent) {
  return function ServerStatusCheck(props) {
    return (
      <div>
        <WrappedComponent {...props} />
      </div>
    );
  };
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  },
});

export class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      is_token_expired: true,
      isLoading: true,
      isTimeout: false,
    };
  }

  logout = () => {
    localStorage.clear();
    localStorage.removeItem("activeNavItem");
    this.props.logout();
  };

  componentDidMount() {
    let time = APIBaseURL === "http://devbusiness.kayana.co.uk/" ? 1800 : 3600;

    timer = new IdleTimer({
      timeout: time, //expire after 10 seconds
      onTimeout: () => {
        this.setState({ isTimeout: true }, () => {});

        if (this.state.isTimeout) {
          // this.logout();

          if (window.location.pathname !== "/login") {
            if (window.location.pathname !== "/") {
              window.location.pathname = "/";
            }
          }

          if (window.location.pathname !== "/") {
            if (window.location.pathname !== "/login") {
              window.location.pathname = "/";
            }
          }
        }
      },

      onExpired: () => {
        this.setState({ isTimeout: true });
        if (this.state.isTimeout) {
          this.logout();
          if (window.location.pathname !== "/login") {
            if (window.location.pathname !== "/") {
              window.location.pathname = "/";
            }
          }

          if (window.location.pathname !== "/") {
            if (window.location.pathname !== "/login") {
              window.location.pathname = "/";
            }
          }
        }
      },
    });
    //  }
  }

  componentWillUnmount() {
    timer.cleanUp();
  }

  render() {
    return (
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <DialogProvider>
            <LoadingProvider>
              <Routes />
              <Dialog />
            </LoadingProvider>
          </DialogProvider>{" "}
          <ToastContainer position="top-center" />
        </ThemeProvider>
      </QueryClientProvider>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
  logout,
});

export default withServerStatusCheck(
  connect(null, mapDispatchToProps)(withRouter((props) => <App {...props} />))
);
