import axios from "axios";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import App from "./container/App/App";
import store from "./redux/store";
import * as APIUrl from "./services/APIUrl";
import { toast } from "react-toastify";
import "./assets/css/utils.css";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import ReactGA from "react-ga";
import reportWebVitals from "./reportWebVitals";
import ReportBugDialog from "components/commonComps/ReportBugDialog";
import { getDialogMethods } from "utils/DialogService";

let persistor = persistStore(store);
const history = createBrowserHistory();

let dialogInstance;

export const setDialogInstance = (dialog) => {
  dialogInstance = dialog;
};
axios.defaults.baseURL = APIUrl.APIBaseURL;

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("access_token");
    const prop_id = localStorage.getItem("prop_id"); // Add propertyId retrieval

    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
      config.headers["Property-Id"] = prop_id;
      config.headers["Access-Control-Allow-Origin"] = true;
      config.headers["username"] = localStorage.getItem("username")
        ? localStorage.getItem("username")
        : "";
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const { openDialog } = getDialogMethods();
    console.log("Error in axios interceptor  at index js:: ", error);

    const errorUrl = error.config?.url || "";
    const excludedUrls = ["https://api.ipify.org", "https://ipapi.co"];

    // ✅ Exclude IP & Geolocation APIs from triggering error dialogs
    if (excludedUrls.some((url) => errorUrl.includes(url))) {
      console.warn(`Skipping error handling for: ${errorUrl}`);
      return Promise.reject(error);
    }

    const errorMessage =
      error.response?.data?.message ||
      error.message ||
      "An unexpected error occurred.";
    const errorDetails = {
      status: error.response?.status || "Unknown",
      url: error.config?.url || "Unknown",
      method: error.config?.method || "Unknown",
    };

    openDialog({
      content: (
        <ReportBugDialog
          errorMessage={errorMessage}
          errorDetails={errorDetails}
        />
      ),
      dialogProps: { maxWidth: "sm" },
    });
    const originalRequest = error.config;

    if (
      error.response &&
      error.response.status === 401 &&
      originalRequest.url ===
        process.env.REACT_APP_API_BASE_URL +
          "/business/authentication/renew-token"
    ) {
      if (
        window.location.pathname !== "/login" ||
        window.location.pathname !== "/"
      ) {
        localStorage.clear();
        localStorage.removeItem("activeNavItem");
        Router.push("/");
      }
      // createBrowserHistory().push('/');
      return Promise.reject(error);
    }

    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      const refreshToken = localStorage.getItem("refresh_token");
      axios({
        method: "get",
        url: "/business/authentication/renew-token",
        headers: {
          RefreshToken: refreshToken,
        },
      })
        .then((response) => {
          if (response && response.data && response.data.status) {
            // localStorage.removeItem("access_token");
            const res = "Bearer " + response.data.data.access_token;
            localStorage.setItem("access_token", res);
            window.location.reload();
            return response;
          } else {
            if (
              window.location.pathname !== "/" ||
              window.location.pathname !== "/login"
            ) {
              localStorage.clear();
              localStorage.removeItem("activeNavItem");
              createBrowserHistory().push("/");
              window.location.reload();
            }
          }
        })
        .catch((error) => {
          console.log("Error==>", error);
          if (error.response) {
            console.log("Server responded with: ", error.response.status);
            toast.error("Server responded with: " + error.response.status);
            if (error.response.status >= 500) {
              console.log("Server is down or encountered an error");
              toast.error("Server is down or encountered an error");
            }
          } else if (error.request) {
            console.log("No response received from the server");
            toast.error("No response received from the server");
          } else {
            console.log("Error: ", error.message);
            toast.error("Error: " + error.message);
          }
          return Promise.reject(error);
        });
    }
  }
);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router forceRefresh={false} history={history}>
        <App />
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById("costic")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

const SendAnalytics = () => {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
};
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(SendAnalytics);
