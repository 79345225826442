import punycode from 'punycode';

const urlMap = {
  "/reports/data-report": "is_master_franchise",
  "/reports/dog-dollar-correction": "is_master_franchise",
  "/reports/total": "is_master_franchise",
  "/reports/charlie": "is_master_franchise",
  "/manage-qr-flow": "is_qr_ordering",
  "/pricing": "is_qr_ordering",
  "/table": "is_qr_ordering",
  "/room": "is_qr_ordering",
  "/generate-qr-code": "is_qr_ordering",
  "/pay-by-link": "is_pay_by_link",
  "/pay-by-link-transactions": "is_pay_by_link",
  "/pay-by-link-transaction-details": "is_pay_by_link",
  "/grant-details": "is_cash_advance",
  "/grants": "is_cash_advance",
  "/grant-accept": "is_cash_advance",
  "/capital-terms-conditions": "is_cash_advance",
  "/suite": "is_suite",
  "/booked-suite": "is_suite",
};
const urlMap2 = {
  "/tip": "",
  "/epos-now": "",
  "/manage-user": "",
  "/billing": "",
  "/delivery-charges": "",
  "/shift": "",
  "/web-order-accept": "",
};

export function isNotAuthorized(pathname, flag) {
  if (
    flag.accountStatus !== "ACCOUNT COMPLETED" &&
    urlMap2.hasOwnProperty(pathname)
  ) {
    return true;
  }

  if (pathname && flag) {
    let flag_key = urlMap[pathname];

    if (!flag_key) return false;

    return !flag?.[flag_key];
  }

  // Default return value if pathname or flag are not provided
  return false;
}
export const validateRequired = (value) =>
  value ? null : "This field is required";

export const composeValidators =
  (...validators) =>
  (value) =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined
    );

export const validateEmail = (value) =>
  value
    ?.toLowerCase()
    ?.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
    ? null
    : "Invalid Email";
export const minLength = (min, message) => (value) =>
  value && value.length < min
    ? message
      ? message
      : `Must be ${min} characters or more`
    : undefined;

export const isValidURL = (inputUrl) => {
  try {
    let url = inputUrl.trim();

    // Remove any URI encoding from previous processing attempts
    url = decodeURIComponent(url);

    // Check for empty string after cleanup
    if (!url) return false;

    // Add https:// if missing and doesn't start with xn--
    if (!/^(https?|ftp):\/\//i.test(url) && !url.startsWith("xn--")) {
      url = `https://${url}`;
    }

    // Basic regex check that allows Unicode characters
    const urlPattern =
      /^(https?|ftp):\/\/(?:[^\s:@\/]+(?::[^\s:@\/]*)?@)?(?:([^\s\/?#]+)|\[[^\]]+\])(?::\d+)?(?:\/[^\s?#]*)?(?:\?[^\s#]*)?(?:#\S*)?$/iu;
    if (!urlPattern.test(url)) return false;

    // Handle IDN to Punycode conversion
    const urlObj = new URL(url);
    const asciiDomain = punycode.toASCII(urlObj.hostname);

    // Additional check for a valid domain
    const domainPattern = /^[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+$/;
    if (!domainPattern.test(asciiDomain)) return false;

    const normalizedUrl = url.replace(urlObj.hostname, asciiDomain);

    // Final validation with proper Punycode conversion
    new URL(normalizedUrl);
    return true;
  } catch (error) {
    return false;
  }
};
